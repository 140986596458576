<template>
	<!-- 个人中心 账户信息卡片 -->
	<a-card :bordered="false" class="header-solid h-full card-profile-information" :bodyStyle="{ paddingTop: 0, paddingBottom: 0 }">
		<template #title>
			<h6 class="font-semibold m-0">个人基本信息</h6>
		</template>
		<a-descriptions title="" :column="1">
			
			<a-descriptions-item label="所属部门">{{ userInfo.bumen }}</a-descriptions-item>
			<a-descriptions-item label="职务">{{ userInfo.job }}</a-descriptions-item>
			<a-descriptions-item label="姓名">{{ userInfo.qy_name }}</a-descriptions-item>
		    <a-descriptions-item label="昵称">{{ userInfo.nickname }}</a-descriptions-item>
		    <a-descriptions-item label="手机号">{{ userInfo.mobile ? userInfo.mobile : "无" }}</a-descriptions-item>
		    <a-descriptions-item label="UID">{{ userInfo.user_id }}</a-descriptions-item>
			<a-descriptions-item label="注册时间">{{ joinTime }}</a-descriptions-item>
		</a-descriptions>
	</a-card>
</template>

<script>
	import { timestampToTime } from "@/utils/tools.js"
	import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
	export default {
		data() {
			return {
				joinTime: ""
			}
		},
		computed: {
			...mapGetters("user", ["userInfo"])
		},
		created() {
			this.joinTime = timestampToTime(this.userInfo.jointime)
		}
	}
</script>
