<template>
    <!-- 个人中心 邀请人员列表 -->
    <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ paddingTop: 0, paddingBottom: '16px' }">
        <template #title>
            <div class="top">
                <div><h6 class="font-semibold m-0">部门信息</h6></div>
                <div v-if="is_admin"><a-button size="small" @click="showModal"><a-icon type="plus-circle" />添加部门</a-button></div>
                <a-modal
                    v-model="modalShowA"
                    title="添加部门"
                    @ok="handleOk"
                    @cancel="handleCancel"
                    okText="确认"
                    cancelText="取消"
                >
                <div>
                        <a-input v-model="department" placeholder="部门名称" />
                </div>
                </a-modal>
				<a-modal
				    v-model="modalShowB"
				    title="编辑部门"
				    @ok="edit_d(item.id)"
				    @cancel="handleCancel"
				    okText="确认"
				    cancelText="取消"
				>
				    <div>
				        <a-input v-model="edit_department" placeholder="部门名称" />
				    </div>
				</a-modal>
            </div>

        </template>
        <div style="height: 300px;overflow: auto;">
		<div  v-for="(item,index) in list">
            <div class="box">
                <div class="d_name">
                    {{item.department}}
                </div>
                <div class="option" v-if="is_admin">
                        <a-button @click="showModalB(item.department)" class="d_btn" size="small"><a-icon type="edit" />编辑</a-button>
                        <a-button class="d_btn" type="danger" @click="showConfirm(item.id)" size="small"><a-icon type="delete" />删除</a-button>
                </div>
                
            </div>
        </div>
</div>

    </a-card>
</template>

<script setup>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
export default {
    data() {
        return {
            modalShowA: false,
            modalShowB: false,
            department:'',
            edit_department:'',
            list:[],
			is_admin:0
        }
    },
    methods:{
        showConfirm(id) {
            const _this = this;
            this.$confirm({
                title: '删除部门',
                content: '是否确定删除该部门',
                okText: '确认',
                cancelText: '取消',
                onOk() {
                    _this.$http("role.del_department",{id:id}).then(res => {
                        // console.log(res);
                        if(res.code == 1){
                            _this.$message.success(res.msg)
                            _this.list = res.data.department;
							_this.is_admin = res.data.is_admin;
                        }

                    })
                },
                onCancel() {},
            });
        },
        showModal() {

            this.modalShowA = true;

        },
        showModalB(department){
            this.modalShowB = true;
            this.edit_department = department;
        },
        handleOk(e) {
            this.$http("role.add_department",{department:this.department}).then(res => {
                // console.log(res);
                if(res.code == 1){
                    this.$message.success(res.msg)
                    this.list = res.data;
                }

            })
            this.modalShowA = false;
        },
        edit_d(id){
            console.log(id);
            this.$http("role.edit_department",{id:id,department:this.edit_department}).then(res => {
                // console.log(res);
                if(res.code == 1){
                    this.list = res.data;
                }

            })
            this.modalShowB = false;
        },
        handleCancel(e) {
            this.modalShowA = false;
        },
        get_department(){
            this.$http("role.get_department",{department:this.department}).then(res => {
                // console.log(res);
                if(res.code == 1){
                    this.list = res.data.department;
					this.is_admin = res.data.is_admin;
                }

            })
        }
    },
    computed: {
        ...mapGetters("user", ["statisticInfo"]),
        shareUserList() {
            return this.statisticInfo.share_user_list
        }
    },
    created() {
        this.get_department();
    },
}
</script>
<style lang="scss" scoped>
.top{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.box{
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.d_name{
    width: 75%;
	height: 50px;
}
.d_btn{
    padding: 0 8px;
    height: 25px;
    margin-left: 10px;
}
.option{
    width: 40%;
    display: flex;
	height: 50px;
    // flex-wrap: wrap;


}
</style>
