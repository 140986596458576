import Vue from "vue"
import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

const modulesFiles = require.context("./modules", true, /\.js$/)
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
	const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1")
	const value = modulesFiles(modulePath)
	modules[moduleName] = value.default
	return modules
}, {})

const store = new Vuex.Store({
	plugins: [
		createPersistedState({
			key: "vuex",
			storage: window.localStorage,
			reducer(val) {
				return {
					app: {
						config: val.app.config
					},
					user: {
						token: val.user.token,
						userInfo: val.user.userInfo,
						qiyeban: val.user.qiyeban// 初始化你的参数
					}
				}
			}
		})
	],
	modules
})

export default store
