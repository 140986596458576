<template>
	<!-- Layout Footer -->
	<a-layout-footer class="mb-5" >
		<a-row type="flex">
				<div class="copyrights font-regular text-sm">
					<div class="item" v-for="(item, index) in config.copyrights" :key="'copyright_' + index">
						<img style="height: 12px;" v-if="item.image" :src="item.image" alt="" />
						<a v-if="item.url" :href="item.url" target="_blank" rel="">
							<span>{{ item.name }}</span>
						</a>
						<span v-else>{{ item.name }}</span>
					</div>
					<!-- <div class="ignore">
						<a href="javascript:translate.changeLanguage('english');">English</a>|
						<a href="javascript:translate.changeLanguage('chinese_simplified');">简体中文</a>|
						<a href="javascript:translate.changeLanguage('chinese_traditional');">繁體中文</a>
					</div> -->
				</div>
			<!-- </a-col> -->
		</a-row>
	</a-layout-footer>
</template>

<script>
	import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
	export default {
		data() {
			return {}
		},
		mounted() {
		// translate.selectLanguageTag.show = false; //不出现的select的选择语言
		// translate.language.setLocal('chinese_simplified'); //设置本地语种（当前网页的语种）。如果不设置，默认自动识别当前网页显示文字的语种。 可填写如 'english'、'chinese_simplified' 等，具体参见文档下方关于此的说明。
		// translate.execute();//进行翻译 
		},
		watch: {
			'$route': function(to, from) {
			  this.$nextTick(() => {
				translate.execute();
			  });
			}
		  },
		computed: {
			...mapGetters("app", ["config"])
		},
		created() {},
		methods: {}
	}
</script>

<style lang="scss" scoped>
	
	.copyrights {
		display: flex;
		align-items: center;
		width: 90%;
		margin-left: 20px;

		.item {
			margin-right: 12px;
			display: flex;
			align-items: center;
            
			&:last-child {
				margin-right: 0;
			}

			img {
				margin-right: 4px;
			}

			span {
				color: #8c8c8c;
			}
		}
	}
</style>
