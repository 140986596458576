<template>
	<!-- 个人中心 基本信息卡片 -->
	<a-card :bordered="false" class="header-solid h-full card-profile-information" :bodyStyle="{ paddingTop: 0, paddingBottom: '16px' }" :headStyle="{ paddingRight: 0 }">
		<template #title>
			<div><h6 class="font-semibold m-0">公司基本信息</h6></div>
			<div v-if="!userInfo.company.name" style="float: right;margin-top: -30px;margin-right: 20px;"><a-button size="small" @click="showModal"><a-icon type="plus-circle" />添加公司</a-button></div>
			<a-modal
				v-model="modalShowAs"
				title="添加公司"
				@ok="handleOk"
				@cancel="handleCancel"
				okText="确认"
				cancelText="取消"
			>
			<div>
					<a-input v-model="name" placeholder="公司名称" />
					<a-input style="margin-top: 10px;" v-model="bianhao" placeholder="企业代码" />
					<a-input style="margin-top: 10px;" v-model="address" placeholder="地址" />
					<a-tree-select
					    v-model="hyvalue"
					    style="width: 100%;margin-top: 10px;"
					    :dropdown-style="{maxHeight:'400px',overflow:'auto'}"
					    :tree-data="treeData"
					    placeholder="请选择行业"
					  >
					</a-tree-select>
			</div>
			</a-modal>
		</template>
		<a-descriptions title="" :column="1">
			<a-descriptions-item label="公司名">{{ userInfo.company.name }}</a-descriptions-item>
			<a-descriptions-item label="企业代码">{{ userInfo.company.bianhao }}</a-descriptions-item>
			<a-descriptions-item label="地址">{{  userInfo.company.address  }}</a-descriptions-item>
			<a-descriptions-item label="企业权益到期时间">{{ userInfo.company.vip_time }}</a-descriptions-item>
			<a-descriptions-item v-if="userInfo.cjgly==1" label="管理员ID">{{ userInfo.company.gly_id }}</a-descriptions-item>
			<a-descriptions-item v-if="userInfo.cjgly==1" label="企业子账号">{{ userInfo.company.zhz_num }}个</a-descriptions-item>
			<a-descriptions-item v-if="userInfo.cjgly==1" label="已授权账号">{{ userInfo.company.zh_num }}个</a-descriptions-item>
			<a-descriptions-item v-if="userInfo.cjgly==1" label="剩余可授权账号">{{ userInfo.company.zhz_num-userInfo.company.zh_num }}个</a-descriptions-item>
			<!-- <a-descriptions-item v-if="userInfo.cjgly==1" label="子管理员ID">{{ userInfo.company.gly_id_z }}</a-descriptions-item> -->
		</a-descriptions>
	</a-card>
</template>

<script setup>
	import { timestampToTime } from "@/utils/tools.js"
	import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
	export default {
		data() {
			return {
				joinTime: "",
				 modalShowAs: false,
				 hyvalue: '请选择行业',
				 treeData:[],
				 name:'',
				 bianhao:'',
				 address:'',
			}
		},
		computed: {
			...mapGetters("user", ["userInfo"])
		},
		created() {
			this.joinTime = timestampToTime(this.userInfo.createtime)
			this.gethangye()
		},
		methods:{
			showModal() {
			    this.modalShowAs = true;
			},
			gethangye(){
				this.$http("write.gethangye").then(res => {
					if (res.code === 1) {
						this.treeData = res.data
					}
				})
			},
			handleOk(e) {
			    this.$http("role.add_company",{name:this.name,address:this.address,bianhao:this.bianhao,hyvalue:this.hyvalue}).then(res => {
			        if(res.code == 1){
			            this.$message.success(res.msg)
						location.reload();
			            // this.list = res.data;
			        }
			
			    })
			    this.modalShowAs= false;
			},
			handleCancel(e) {
			    this.modalShowAs = false;
			},
		}
	}
</script>
