<template>
    <!-- 个人中心 邀请人员列表 -->
    <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ paddingTop: 0, paddingBottom: '16px' }">
        <template #title>
            <div class="top">
                <div><h6 class="font-semibold m-0">公司成员信息</h6></div>
                <div v-if="is_admin"><a-button size="small" @click="showModal"><a-icon type="plus-circle" />添加成员</a-button></div>
                <a-modal
                    v-model="modalShowA"
                    title="添加成员"
                    @ok="handleOk"
                    @cancel="handleCancel"
                    okText="确认"
                    cancelText="取消"
                >
                    <a-select class="inp" @change="handleChange2" placeholder="选择部门">
                        <a-select-option v-for="(item,index) in d_list" :value="item.id">
                            {{item.department}}
                        </a-select-option>
                    </a-select>
                    <a-input class="inp" v-model="phone" placeholder="成员手机号" />
                    <a-input class="inp" v-model="name" placeholder="姓名" />
                    <a-input class="inp" v-model="job" placeholder="职位" />
                </a-modal>
            </div>
        </template>
        <a-list class="conversations-list" item-layout="horizontal" :split="false" :data-source="m_list">
            <a-list-item slot="renderItem" slot-scope="item">

                <a-list-item-meta :title="item.name +'-'+ item.department_name +'  -'+item.job">
                    <a-avatar slot="avatar" :size="48" shape="square" :src="item.avatar" />
                </a-list-item-meta>
                <a-modal
                    v-model="modalShowB"
                    title="编辑成员"
                    @ok="edit_d(edit_box.id)"
                    @cancel="handleCancel"
                    okText="确认"
                    cancelText="取消"
                >
                    <div>
                        <a-select class="inp" @change="handleChange" :placeholder="edit_department">
                            <a-select-option v-for="(item,index) in d_list" :value=item.id>
                                {{item.department}}
                            </a-select-option>

                        </a-select>

                        <a-input class="inp" v-model="edit_name" placeholder="姓名" />
                        <a-input class="inp" v-model="edit_job" placeholder="职位" />
                    </div>
                </a-modal>
                <div class="option" v-if="is_admin">
                    <div>
                        <a-button @click="showModalB(item)" class="d_btn" size="small"><a-icon type="edit" />编辑</a-button>

                    </div>
                    <div>
                        <a-button class="d_btn" type="danger" @click="showConfirm(item.id)" size="small"><a-icon type="delete" />删除</a-button>
                    </div>

                </div>

            </a-list-item>

        </a-list>
    </a-card>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
export default {
    data() {
        return {
            modalShowA: false,
            modalShowB: false,
            phone:'',
            edit_name:'',
            edit_department:'',
            edit_job:'',
            edit_department_id:'',
            list:[],
            name:'',
            job:'',
            d_list:[],
            m_list:[],
            department_id:'',
            edit_box:[],
			is_admin:false,
        }
    },
    methods:{
        showConfirm(id) {
            const _this = this;
            this.$confirm({
                title: '删除删除',
                content: '是否确定删除该成员',
                okText: '确认',
                cancelText: '取消',
                onOk() {
                    _this.$http("role.del_department_user",{id:id}).then(res => {
                        // console.log(res);
                        if(res.code == 1){
                            _this.$message.success(res.msg)
                            _this.m_list = res.data;
                        }

                    })
                },
                onCancel() {},
            });
        },
        handleChange2(value){
            this.department_id = value;
        },
        edit_d(id){
            console.log(id);
            this.$http("role.edit_department_user",{id:id,job:this.edit_job,name:this.edit_name,department:this.edit_department_id}).then(res => {
                // console.log(res);
                if(res.code == 1){
                    this.m_list = res.data;
                }

            })
            this.modalShowB = false;
        },
        showModalB(item){
            console.log(item);
            this.edit_box = item;
            this.modalShowB = true;
            this.edit_name = item.name;
            this.edit_department = item.department_name;
            this.edit_job = item.job;
        },
        handleChange(value) {
            this.edit_department_id = value;
        },
        showModal() {
            this.modalShowA = true;
        },
        handleOk(e) {
            this.$http("role.add_member",{phone:this.phone,name:this.name,job:this.job,id:this.department_id}).then(res => {
                // console.log(res);
                if(res.code == 1){
                    this.$message.success(res.msg)
                    this.m_list = res.data;
                }

            })
            this.modalShowA = false;
        },
        handleCancel(e) {
            this.modalShowA = false;
        },
        get_department(){
            this.$http("role.get_department").then(res => {
                // console.log(res);
                if(res.code == 1){
                    this.d_list = res.data.department;
					
                }

            })
        },
        get_department_user(){
            this.$http("role.get_department_user").then(res => {
                // console.log(res);
                if(res.code == 1){
                    this.m_list = res.data.user;
					this.is_admin = res.data.is_admin;
                }
            })
        }
    },

    computed: {
        ...mapGetters("user", ["statisticInfo"]),
        shareUserList() {
            return this.statisticInfo.share_user_list
        }
    },
    created() {
        this.get_department();
        this.get_department_user();
    }
}
</script>
<style lang="scss" scoped>
.top{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.search{
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.inp{
    width: 100%;
    margin-bottom: 10px;
}
.search div:nth-child(1){
    width: 80%;
}
.search div:nth-child(2){
    width: 20%;
    display: flex;
    justify-content: flex-end;
}
.box{
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.d_name{
    width: 75%;
}
.d_btn{
    padding: 0 8px;
    height: 25px;
    margin-left: 10px;
}
.option{
    width: 25%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;


}
</style>